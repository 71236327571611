/* App.css */
body {
  margin: 0;
  background-color: #f0f0f0; /* 전체 화면의 배경색 설정 */
}

.app-container {
  max-width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
}

.logo {
  height: 60px;
  margin-top: 20px;
  margin-bottom: 20px;
  align-self: center;
}

.separator {
  height: 1px;
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 100%;
  background: #ccc;
}

.title-editor {
  max-width: 400px;
  min-width: 80px;
  height: 30px;
  font-size: 18px;
  border: none;
  padding: 5px;
  margin: 10px;
  border-bottom: 1px solid #ccc;
}

.subtitle-editor {
  max-width: 200px;
  height: 20px;
  font-size: 12px;
  color: gray;
  border: none;
  padding: 5px;
}

.editor-container {
  flex: 1;
  padding: 10px;
}


.icon-button {
  background-color: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ccc;
  }

  &:active {
    background-color: #ddd;
  }
}


.copied-notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.5s ease-in-out;
}

.copied-notification.hidden {
  opacity: 0;
  z-index: -1;
}