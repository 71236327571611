/* Modal.css */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* 어두운 배경색 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* 다른 요소 위에 나타나도록 설정 */

    .modal-content {
        background: white;
        padding: 20px;
        min-width: 300px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        text-align: center;
    }

    h2 {
        margin-top: 5px;
    }

    p {
        min-width: 60px;
        margin: 0px;
        padding: 5px;
    }

    label {
        display: flex;
        margin-bottom: 10px;
    }

    input {
        width: 80%;
        height: 10px;
        padding: 8px;
        margin: 0px;
    }

    .modal-buttons {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        button {
            margin: 0 10px;
            padding: 8px 16px;
            cursor: pointer;
            border: none;
            border-radius: 4px;
            font-size: 14px;
        }

        button:first-child {
            background-color: #4caf50; /* 확인 버튼 색상 */
            color: white;
        }

        button:last-child {
            background-color: #CD5C5C; /* 취소 버튼 색상 */
            color: white;
        }
    }
}
  