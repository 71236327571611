.tabs-container {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: calc(100vh - 141px);
  padding-bottom: 10px;
  border-right: 1px solid #ccc;
}

.tabs-scrollable {
  overflow-y: auto;
  margin-bottom: 5px;
}

.tab {
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}
  
.active-tab {
  background-color: #a0a0a0;
  color: black;
}

.function-tab-container {
  margin-top: auto;
}
  
.add-tab,
.clear-tab,
.copy-tab {
  margin-top: 4px;
  padding: 6px;
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
}

.add-tab {
  background-color: #4caf50;
  color: white;
}

.clear-tab {
  background-color: #CD5C5C;
  color: white;
}

.copy-tab {
  background-color: gray;
  color: white;
}

.file-tab-container {
  margin-top: auto;
  display: flex;
  margin-top: 4px;
  cursor: pointer;
  justify-content: space-between;
}

.import-tab,
.export-tab {
  width: calc(50% - 20px);
  text-align: center;
  padding: 6px;
  border-radius: 3px;
}

.import-tab {
  background-color: #ccc;
  color: white;
}

.export-tab {
  background-color: #ccc;
  color: white;
}

.subtitle {
  font-size: 10px;
  color: gray;
}