.ql-snow {
    .ql-picker {
        &.ql-size {
            .ql-picker-label, .ql-picker-item {
                &::before {
                    content: attr(data-value) !important;
                }
            }
        } 
    }
}

/* TextEditor.css */
.text-editor-container {
    margin: 10px;
    flex-direction: column;
}

.ql-editor {
    min-height: 250px !important;
    max-height: calc(100vh - 250px);
    overflow: hidden;
    overflow-y: scroll;
}